import React from "react"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
  },
  title: {
    marginTop: 0,
    paddingTop: "1rem",
  },
  subtitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  inlineIcon: {
    position: "relative",
    top: "3px",
    width: theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
  },
  content: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.primary.dark,
    },
    "& ul": {
      listStyleType: "circle",
    },
    "& ul p": {
      margin: 0,
      padding: 0,
    },
  },
}))

const ImprintPage = ({ data, location }) => {
  const classes = useStyles()
  const { twitter } = data // data.markdownRemark holds your post data

  return (
    <Layout dark>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Imprint
        </Typography>
        <Typography variant="body1">
          <br />
          triple w team AG <br />
          Burghaldenstrasse 29 <br />
          5027 Herznach - Switzerland
          <br />
          <br />
          Commercial register number: CHE-109.859.673
          <br />
          Email:{" "}
          <a href="mailto:hello@simpleen.io" className={classes.a}>
            hello@simpleen.io
          </a>
        </Typography>
      </Container>
      <Seo
        general={{
          title: "Imprint - simpleen.io",
          description: "Imprint",
          language: "en",
          path: location.pathname,
          image: twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const privacyQuery = graphql`
  query SimpleenImprintPageQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageFormat.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
  }
`

export default ImprintPage
